import Vue from 'vue'
import store from '@/store/store.js'
import VueRouter from 'vue-router'
import App from './App.vue'
import { ToastPlugin } from 'bootstrap-vue'

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// /* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
/* add icons */
import {
  faCopy,
  faUser,
  faGears,
  faArrowUp,
  faArrowDown,
  faEllipsis,
  faEllipsisV,
  faPenToSquare,
  faChevronRight,
  faChevronLeft,
  faPlus,
  faImages,
  faMicrochip, faProjectDiagram, faImage, faFileAlt, faPaintBrush, faBolt,
  faTrash, faUpload, faPaperPlane
} from '@fortawesome/free-solid-svg-icons'
library.add(

  faCopy,
  faUser,
  faGears,
  faArrowUp,
  faArrowDown,
  faEllipsis,
  faEllipsisV,
  faPenToSquare,
  faChevronRight,
  faChevronLeft,
  faPlus,
  faImages,
  faMicrochip, faProjectDiagram, faImage, faFileAlt, faPaintBrush, faBolt,
  faTrash, faUpload, faPaperPlane
)

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import VueMeta from 'vue-meta'
Vue.use(VueMeta)

// Make BootstrapVue available throughout your project
import { BButton, BModal, BTable, BRow, BCol, BContainer, BCard, BCardBody,
  BCollapse, BCardText, BButtonGroup, BFormTextarea, BFormCheckbox, BFormInput, BFormGroup, BForm,
BImg, BTab, BTabs, BCardGroup, BListGroupItem, BListGroup, BAlert,
BNavItem, BFormSelect, VBToggle, BFormFile, BProgress, VBPopover,
 BBadge, BPagination, BPopover, BSkeletonTable, BCardHeader, BSidebar, BSkeletonImg, BFormCheckboxGroup, BFormRadioGroup} from 'bootstrap-vue'

Vue.component('b-button', BButton)
Vue.component('b-sidebar', BSidebar)
Vue.component('b-skeleton-table', BSkeletonTable)
Vue.component('b-skeleton-img', BSkeletonImg)
Vue.component('b-pagination', BPagination)
Vue.component('b-badge', BBadge)
Vue.component('b-popover', BPopover)
Vue.component('b-progress', BProgress)
Vue.component('b-form-file', BFormFile)
Vue.component('b-modal', BModal)
Vue.component('b-table', BTable)
Vue.component('b-row', BRow)
Vue.component('b-col', BCol)
Vue.component('b-container', BContainer)
Vue.component('b-card', BCard)
Vue.component('b-card-header', BCardHeader)
Vue.component('b-card-body', BCardBody)
Vue.component('b-card-text', BCardText)
Vue.component('b-collapse', BCollapse)
Vue.component('b-button-group', BButtonGroup)
Vue.component('b-form-textarea', BFormTextarea)
Vue.component('b-form-checkbox', BFormCheckbox)
Vue.component('b-form-checkbox-group', BFormCheckboxGroup)
Vue.component('b-form-radio-group', BFormRadioGroup)
Vue.component('b-form-input', BFormInput)
Vue.component('b-form-group', BFormGroup)
Vue.component('b-form', BForm)
Vue.component('b-img', BImg)
Vue.component('b-tab', BTab)
Vue.component('b-tabs', BTabs)
Vue.component('b-card-group', BCardGroup)
Vue.component('b-list-group-item', BListGroupItem)
Vue.component('b-list-group', BListGroup)
Vue.component('b-alert', BAlert)
Vue.component('b-nav-item', BNavItem)
Vue.component('b-form-select', BFormSelect)
Vue.component('b-toggle', BFormSelect)
Vue.directive('b-toggle', VBToggle)
Vue.directive('b-popover', VBPopover)

import '@/styles/_variables.scss' // Import the _variables.scss file

// Optionally install the BootstrapVue icon components plugin
Vue.config.productionTip = false
Vue.use(VueRouter)
Vue.use(ToastPlugin)
/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)

var link =
  document.querySelector("link[rel*='icon']") || document.createElement('link')
link.type = 'image/x-icon'
link.rel = 'shortcut icon'
link.href = '/favicon.ico'
document.getElementsByTagName('head')[0].appendChild(link)

// Define routes with code splitting
const routes = [
  { path: '/', component: () => import('./pages/HomePage') },
  { path: '/affiliate', component: () => import('./pages/AffiliateOnboarding'), meta: { requiresAdmin: true }  },
  { path: '/home', component: () => import('./pages/HomePage') },
  { path: '/blog', component: () => import('./pages/BlogPage') },
  { path: '/chat', component: () => import('./pages/ChatPage') },
  { path: '/projects', component: () => import('./pages/ProjectsPage') },
  { path: '/image', component: () => import('./pages/ImagePage') },
  { path: '/token-calculator', component: () => import('./pages/TokenPage') },
  { path: '/logout', component: () => import('./pages/LogoutPage') },
  { path: '/login', component: () => import('./pages/LoginPage') },
  { path: '/signup', component: () => import('./pages/SignUpPage') },
  { path: '/profile', component: () => import('./pages/ProfilePage') },
  { path: '/pricing', component: () => import('./pages/PricingPage') },
  { path: '/privacy', component: () => import('./pages/PrivacyPolicyPage') },
  { path: '/paymentReceived', component: () => import('./pages/PaymentPage') },
  { path: '/feedback', component: () => import('./pages/FeedbackPage') },
  { path: '/reset-password', component: () => import('./pages/ResetPasswordPage') },
  { path: '/terms', component: () => import('./pages/TermsOfServicePage') },
  { path: '/video', component: () => import('./pages/VideoPage') },

  // Admin
  { path: '/admin', component: () => import('./pages/AdminUtilitiesPage'), meta: { requiresAdmin: true },
    children: [
      { path: 'click-tracking', component: () => import('./pages/AdminClickTrackingPage'), meta: { requiresAdmin: true } },
      { path: 'users', component: () => import('./pages/UsersPage'), meta: { requiresAdmin: true } },
      { path: 'dashboard', component: () => import('./pages/DashboardPage'), meta: { requiresAdmin: true } },
      { path: 'feedback', component: () => import('./pages/AdminFeedbackPage.vue'), meta: { requiresAdmin: true } },
    ]
  },

  // Blog Posts
  { path: '/blog/mastering-claude-ai', component: () => import('./components/blog/ClaudeAIGuide.vue') },
  { path: '/blog/ai-models-comparison', component: () => import('./components/blog/AIModelsComparison.vue') },
  { path: '/blog/ai-modeling-explained', component: () => import('./components/blog/AIModelingExplained.vue') },
  { path: '/blog/llms-in-modern-ai', component: () => import('./components/blog/LLMsInModernAI.vue') },
  { path: '/blog/free-ai-tools', component: () => import('./components/blog/FreeAITools.vue') },
  { path: '/blog/llm-comparison', component: () => import('./components/blog/LlmComparison.vue') },
  { path: '/blog/open-ai-o1-series', component: () => import('./components/blog/OpenAIo1.vue') },

  // Vs Routes
  { path: '/blog/claude-opus-vs-gpt-4', component: () => import('./components/blog/vs/ClaudeOpusVsGpt4.vue') },
  { path: '/blog/claude-3-5-vs-gpt-4o', component: () => import('./components/blog/vs/Claude35VsGpt4o.vue') },
  { path: '/blog/claude-3-5-sonnet-vs-opus', component: () => import('./components/blog/vs/Claude35SonnetVsOpus.vue') },
  { path: '/blog/gpt-4o-vs-4', component: () => import('./components/blog/vs/Gpt4oVs4.vue') },
  { path: '/blog/chatgpt-vs-gemini-vs-claude', component: () => import('./components/blog/vs/ChatgptVsGeminiVsClaude.vue') },
  { path: '/blog/llama-3-vs-claude-3', component: () => import('./components/blog/vs/Llama3VsClaude3.vue') },
  { path: '/blog/llama-3-vs-claude-opus', component: () => import('./components/blog/vs/Llama3VsClaudeOpus.vue') },
  { path: '/blog/sonnet-vs-opus', component: () => import('./components/blog/vs/SonnetVsOpus.vue') },
  { path: '/blog/claude-3-5-haiku', component: () => import('./components/blog/vs/Claude35Haiku.vue') },
  { path: '/blog/gpt-3-5-vs-claude-haiku', component: () => import('./components/blog/vs/Gpt35VsClaudeHaiku.vue') },
  { path: '/blog/llama-3-vs-gpt-4', component: () => import('./components/blog/vs/Llama3VsGpt4.vue') },
  { path: '/blog/claude-opus-vs-gpt4o', component: () => import('./components/blog/vs/ClaudeOpusVsGpt4o.vue') },
  { path: '/blog/claude-opus-vs-gpt-4o', component: () => import('./components/blog/vs/ClaudeOpusVsGpt4o.vue') },
  { path: '/blog/gpt-4o-vs-claude-3-5', component: () => import('./components/blog/vs/Gpt4oVsClaude35.vue') },
  { path: '/blog/llama3-vs-gpt4', component: () => import('./components/blog/vs/Llama3VsGpt4.vue') },
  { path: '/blog/claude-sonnet-vs-haiku', component: () => import('./components/blog/vs/ClaudeSonnetVsHaiku.vue') },
  { path: '/blog/chatgpt-4-vs-4o', component: () => import('./components/blog/vs/Chatgpt4Vs4o.vue') },
  { path: '/blog/gpt4o-vs-gpt-4', component: () => import('./components/blog/vs/Gpt4oVsGpt4.vue') },
  { path: '/blog/claude-3-vs-gpt-4o', component: () => import('./components/blog/vs/Claude3VsGpt4o.vue') },
  { path: '/blog/claude-3-sonnet-vs-gpt-4', component: () => import('./components/blog/vs/Claude3SonnetVsGpt4.vue') },
  { path: '/blog/claude-3-5-sonnet-vs-gpt-4o', component: () => import('./components/blog/vs/Claude35SonnetVsGpt4o.vue') },
  { path: '/blog/claude-haiku-vs-sonnet', component: () => import('./components/blog/vs/ClaudeHaikuVsSonnet.vue') },
  { path: '/blog/claude-3-sonnet-vs-gpt-3-5', component: () => import('./components/blog/vs/Claude3SonnetVsGpt35.vue') },

  // Docs
  { 
    path: '/docs', 
    component: () => import('./components/docs/DocsLayout.vue'),
    children: [
      { path: 'models', component: () => import('./components/docs/models/ModelDocumentation.vue') },
      { path: 'models/openai', component: () => import('./components/docs/models/OpenAIModels.vue') },
      { path: 'models/anthropic', component: () => import('./components/docs/models/AnthropicModels.vue') },
      { path: 'models/meta', component: () => import('./components/docs/models/MetaModels.vue') },
      { path: 'models/amazon', component: () => import('./components/docs/models/AmazonModels.vue') },
      { path: 'models/mistral', component: () => import('./components/docs/models/MistralModels.vue') },
      { path: 'models/ai21-jurassic', component: () => import('./components/docs/models/AI21JurassicModels.vue') },
      { path: 'models/cohere', component: () => import('./components/docs/models/CohereModels.vue') },
      { path: 'modelfusion/multichat', component: () => import('./components/docs/modelfusion/MultiChat.vue') },
      { path: 'modelfusion/fusioncredits', component: () => import('./components/docs/modelfusion/FusionCredits.vue') },
      { path: 'modelfusion/projects', component: () => import('./components/docs/modelfusion/Projects.vue') },
      { path: 'modelfusion/image-generation', component: () => import('./components/docs/modelfusion/ImageGeneration.vue') },
      { path: 'modelfusion/chat-with-images', component: () => import('./components/docs/modelfusion/ChatWithImages.vue') },
    ]
  },
  { path: '*', redirect: '/' } // Catch-all route, redirect to home
]


// Create router instance
const router = new VueRouter({
  base: process.env.BASE_URL, // Add this line
  mode: 'history',
  routes,
  parseQuery(query) {
    return Object.fromEntries(new URLSearchParams(query));
  },
  stringifyQuery(query) {
    const result = new URLSearchParams(query).toString();
    return result ? ('?' + result) : '';
  }
})

router.beforeEach((to, from, next) => {
  console.log('LOAD / NAV')
  console.log(from)
  if (from.name !== null && Object.keys(to.query).length === 0 && Object.keys(from.query).length > 0) {
    next({ path: to.path, query: from.query });
    return;
  }

  if (to.matched.some(record => record.meta.requiresAdmin)) {
    if (store.getters.isAdmin) {
      next();
    } else {
      next({ path: '/' });
    }
  } else {
    next();
  }
});

export default router // Export the router

new Vue({
  store,
  router,
  render: (h) => h(App)
}).$mount('#app')

